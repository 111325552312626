<!--
 * @Date: 2022-11-02 14:06:39
 * @LastEditors: JunjieYang
 * @LastEditTime: 2022-11-11 09:13:09
 * @FilePath: \新建文件夹\vue-app\src\App.vue
 * @Description: 
-->
<template>
  <div id="app">
    <img v-for="(item, index) in list" :key="index" :src="item" />
    <div class="beian">
      <p><span class="link right" @click="goPage">浙ICP备2022028976号</span></p>
      <div>
        <a class="link" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012841">
          <img src="./assets/gongan.png"/>
          <p>浙公网安备 33010802012841号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      list: [
        require("./assets/page_01.png"),
        require("./assets/page_02.png"),
        require("./assets/page_03.png"),
        require("./assets/page_04.png"),
        require("./assets/page_05.png"),
      ],
    };
  },
  methods: {
    goPage() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  min-width: 1200px;
}

img {
  width: 100%;
  vertical-align: top;
}

.beian {
  background: #333;
  color: #fff;
  text-align: center;
  color: #939393;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.beian img {
  width: 20px;
  height: 20px;
  vertical-align: center;
  position: relative;
  top: -1px;
  margin-right: 8px;
}
.link {
  display: flex;
  cursor: pointer;
  color: #939393;
  text-decoration: none;
}
.right{
  margin-right: 30px;
}
</style>
